import React from 'react';
const Footer = () => (
  <div>
    <footer className="text-center bg-dark text-light">
      <p>
        <small>
          <small>
            Copyrights <a href="https://surabaya.pages.dev">surabaya dev</a> <br />
            support sponsor :{' '}
            <a href="https://cloudflare.com" target="_blank">
              cloudflare
            </a>{' '}
            and host by{' '}
            <a href="https://github.com" target="_blank">
              github
            </a>{' '}
            build with{' '}
            <a href="https://reactjs.org" target="_blank">
              react
            </a>
          </small>
        </small>
      </p>
    </footer>
  </div>
);

export default Footer;
