const articles = [
  {
    name: 'webmurah',
    title: 'Website Murah',
    desc: 'Pembuatan website murah siap online',
    img:
      'https://1.bp.blogspot.com/-O53c79YZ-8s/YMDUSHql3FI/AAAAAAAAPHA/tDirlIGUHvYWnEOVruMIgXSC5j08bknAACLcBGAsYHQ/s256/printer.ico',
    content: [
      `Kebutuhan untuk pembuatan website murah maka dapat menggunakan beberapa variant dalam digunakan , dan pada web murah dengan harga Rp.368.800/tahun include domain ,my.id maka get axcora cms menjadi alternatif terbaik, content management system untuk kemudahan dalam melakukan update membuat artikel upload image sesuai dengan kebutuhan, dan tentunya didukung oleh tema tema yang sudah tersedia untuk digunakan , tinggal edit konten dan website sudah online.`,
      `Get axcora cms adalah salah satu pioner dari open source code project kami untuk membantu pembuatan website dengan harga murah dengan skema dan konsep tema yang sudah kami sediakan memudahkan untuk pengguna melakukan edit sesuai kebutuhan, perubahan update dan segala nya menjadi lebih bebas dengan get axcora cms ini.`,
      `Konsep dan tema untuk pembuatan website murah sekolah, pembuatan website company profile, website toko shop , website restoran cafe dan semua nya tersedia untuk digunakan.`
    ]
  },
  {
    name: 'cmswebsite',
    title: 'Website CMS',
    desc: 'Pembuatan website dengan content management system',
    img:
      'https://1.bp.blogspot.com/-yRfPckmL8Cg/YMDUL1R1QSI/AAAAAAAAPFw/nUGArcwS8m0Z400rVr_FM4FJvZsFOsAlQCLcBGAsYHQ/s256/BDRom.ico',
    content: [
      `Layanan pembuatan website dan pengembangan dengan menggunakan content management system dalam bekerja tentu saja dengan berbagai dukungan cms populer dan mudah digunakan untuk tujuan agar lebih cepat dan gampang dalam kebutuhan update artikel upload foto dan lain sebagai nya, beberapa pilihan cms siap digunakan dengan desain unik kreatif dan menarik.`,
      `Blogspot, sebuah bloging platform persembahan dari google untuk digunakan , dengan kemudahan untuk edit template nya maka memungkinkan untuk menyulap nya menjadi lebih elegan mewah dan modern, dan tentu saja dukungan SEO menjadi alternatif untuk menggunakan blogspot sebagai website.`,
      `Get axcora cms sebuah open source code project dari team developer kami untuk kemudahan dalam pengembangan dan pembuatan website yang tentunya dukungan SEO menjadi terbaik dengan otomatis generate title deskripsi meta tag dan facebook twitter card, non database menjadikan cms ini lebih cepat layaknya statis site.`,
      `MODX revolutions, sebut dia dengan content management framework tingkatan nya sedikit lebih sulit dibandingkan dengan cms karena semi framework di dalam nya, menggunakan MODX menjadi sensasi tersendiri ini dikarenakan MODX powerfull pada SEO nya dan lebih bebas untuk para developer, penggunaan nya dikhusus kan untuk yang sedikit mengerti codding secara teknis.`,
      `Grav , terpopuler pada kategori flatfile cms , maka alternatif lain untuk anda yang menginginkan sebuah website yang cepat dengan menggunakan nya, dukungan komunitas yang kuat serta pemenang dan pemimpin flatfile cms kategori maka menggunakan grav bisa menjadi alternatif terbaik.`,
      `Wordpress , leader cms dunia maka ini merupakan pilihan untuk anda yang menginginkan membuat website secara cepat, umumnya kami menggunakan wordpress untuk kebutuhan online shop dalam kebutuhan nya.`,
      `CMS untuk pembuatan website sekolah, pembuatan website company profile, website toko shop , website restoran cafe dan semua nya tersedia untuk digunakan.`
    ]
  },
  {
    name: 'statisweb',
    title: 'Static Website',
    desc: 'Static modern website untuk developer web dengan technology baru',
    img:
      'https://1.bp.blogspot.com/-rJggK-n3foA/YMDUPozmSvI/AAAAAAAAPGc/79cDvNHCdz0aNOEwLQX5CF4A22d0TbDFQCLcBGAsYHQ/s256/file%2Bexe.ico',
    content: [
      `Static website adalah sebuah sensasi dengan full native static dalam penggunaan nya, yap.. website ini tanpa menggunakan database sama sekali sehingga dipastikan 100% lebih cepat dalam load webiste , dan tentu saja dengan pengerjaan menggunakan full codding dengan berbagai bahasa maka pengerjaan nya relatif sedikit lebih lama , hal ini dikarenakan dengan full codding dalam pengerjaan nya untuk tujuan website cepat dan bersahabat dengan SEO google, kami sendiri menydiakan berbagai alternatif untuk digunakan.`,
      `Bootstrap Native HTML5 adalah solusi terbaik untuk website super cepat dengan menggunakan pyur html css dan js dalam bekerja , dengan metode ini maka website semakin superior cepat dan sangat stabil tentunya anti hack untuk digunakan mengingat tidak adanya database yang disimpan,,bootstrap menjadi prioritas utama untuk digunakan.`,
      `Jekyll ruby on rails, sebuah terobosan terbaru dari technology dan generasi gem, super cepat lebih cepat akses load nya menjadikan develope pembuatan website dengan jekyll akan semakin menarik untuk digunakan dan tentunya dukungan markdown lang memudahkan untuk update dan blogging secara cepat,jekyll alternatif terbaik untuk membuat situs statis.`
    ]
  },
  {
    name: 'modernwebsite',
    title: 'Modern Site',
    desc: 'Pembuatan website technology terbaru laravel angular react gatsbyjs',
    img:
      'https://1.bp.blogspot.com/-k8Fu1cZrNIc/YMDUSJy7NuI/AAAAAAAAPG8/4t58aJcYZiQgVY185cmLjovZODLBTkAfgCLcBGAsYHQ/s256/run.ico',

    content: [
      `Seiring dengan berkembang pesat nya technology saat ini , maka kini banyak bermunculan framework terbaru dan terbaik hal ini sangat lah positif untuk para developer bekerja dengan menggunakan berbagai technology dalam penggunaan nya, sehingga tidak menggunakan satu bahasa saja tetapi mereka juga dapat mengkombinasi kan dan terintegrasi satu sama lain , ini sangat luar biasa, dan tentu saja framework yang digunakan dalam proses pembuatan website adalah pilihan dan terbaik dalam develope modern site`,
      `Laravel menjadi pilihan mudah untuk para pengguna dan kami sendiri banyak menggunakan nya selain untuk pembuatan webiste laravel juga digunakan untuk aplikasi dan web app terbaik dengan framework ini karena tangguh stabil digunakan, khusus mereka yang menginginkan deploy website menggunakan laravel maka tidak perlu kawatir karena kami juga menyediakan content management system berbasis laravel untuk digunakan , dengan CMS laravel akan mempermudah update artikel secara cepat.`,
      `Symfony salah satu PHP framework yang banyak digunakan oleh cms dunia seperti drupal , dan khusus anda yang menginginkan deploy website menggunakan symfony kamu menyediakan untuk anda berbalaut cms agar mempermudah untuk login update artikel secara cepat via web app kami untuk digunakan.`,
      `Angular, nah framework satu ini benar benar menguras tenaga dan pikiran karena tingkat kesulitan nya, membangun website dengan angular akan menjadi kepuasan tersendiri menjadikan website nampak mewah elegan dalam penerapan nya, dan tentunya kami pun menyediakan layanan jasa pembuatan website angular dengan cms yang memudahkan anda dalam bekerja update artikel secara cepat terintegrasi dengan real time firebase database no sql semakin mempercepat web app anda, sensasi luar biasa didapat dengan akses page to page non load menyempurnakan situs idaman mu.`,
      `React js , terpopuler didunia dan banyak nya developer menggunakan nya dengan dukungan single page apps sama seperti angular membuat website dengan react , gatsby js dan next js akan menyempurnakan kebutuhan web modern cepat, sensasi akses tanpa loading pada tiap tiap halaman menjadikan nya luar biasa untuk digunakan.`
    ]
  },
  {
    name: 'aplikasitoko',
    title: 'Aplikasi Toko',
    desc: 'Pembuatan aplikasi toko untuk toko minimarket shop store',
    img:
      'https://1.bp.blogspot.com/-LN9XNafdS_w/YMDTtI1N88I/AAAAAAAAPFg/F8tF7rdz1kAe3iZ4ZhWhM8jwWhFWw7BXQCLcBGAsYHQ/s256/control%2Bpanel.ico',
    content: [
      `Kami sudah menyiapkan develope sebuah aplikasi toko untuk digunakan dalam berbagai kebutuhan, berbasis offline maupun online system menyempurnakan dalam bekerja via web apps kami, aplikasi toko yang lengkap dengan berbagai dukungan fitur memudahkan dalam proses pencatatan pembukuan mempercepat transaksi support dengan touchscreen metode dengan gambar foto produk pada penjualan hingga barcode system semua terkonsep dengan baik untuk menghasilkan sebuah aplikasi toko yang mudah serta lengkap untuk diguakan.`,
      `Berbagai dukungan technology digunakan untuk menunjang usaha dan bisnis toko anda mulai minimarket, toko , baby shop, toko baju butik, toko kelontong, toko alat olah raga dan ATK, , pet shop dan masih banyak lagi, selain itu beberapa apps kami mendukung penuuh inventori stok persediaan management, expired date fitur , penjualan dengan gambar dan barcode , mendukung penuh touchscreen , clouds app memudahkan akses aplikasi via berbagai device seperti smartphone android iphone, tablet pc desktop dan laptop berbagai OS dalam bekerja.`,
      `Mulai update pembukuan anda sekarang bersama kami untuk yang terbaik , offline desktop program software toko hingga clouds aplikasi toko siap untuk digunakan menunjang bisnis usaha.`
    ]
  },
  {
    name: 'aplikasirestoran',
    title: 'Aplikasi restoran',
    desc: 'Restoran cafe rumah makan dengan aplikasi modern keren.',
    img:
      'https://1.bp.blogspot.com/-e7oB5Fhev7g/YMDUOoZhJUI/AAAAAAAAPGM/lPywVEiUCikuC9T5-zxrekgurocAnrASACLcBGAsYHQ/s256/computer.ico',
    content: [
      `Special untuk usaha kuliner dengan menggunakan aplikasi restoran cafe rumah makan sebut dia sebagai mesin kasir online, bekerja sesuai kebutuhan untuk offline desktop mode maupun clouds online aplikasi tersedia untuk menunjang pembukuan, mulai dari harga yang murah terjangkau dengan fitur simple hingga fitur lengkap dengan kitchen , waiters dan kasir dalam bekerja.`,
      `Metode transaksi penjualan langsung untuk usaha drink food shop metode fastfood mempercepat penjualan kasir dalam penggunaan nya, selanjutnya hadir metode restoran dengan pencatatan meja pelanggan untuk mempermudah proses order waiters dan mengirimkan pesanan ke kitchen hingga kasir mencetak bill maupun struk kasir, selanjutnya semua terangkum dalam laporan laporan detail untuk monitoring kinerja restoran cafe rumah makan .`
    ]
  },
  {
    name: 'invoiceakuntansi',
    title: 'Aplikasi Office',
    desc: 'Aplikasi invoice akuntansi accounting dan inventori stok',
    img:
      'https://1.bp.blogspot.com/-BXN26952W-4/YMDUOCdDt3I/AAAAAAAAPGE/lc4nwcnqFewam5JKq7FAOsCNVnkmmuajgCLcBGAsYHQ/s256/NetworkDrive%2BOnline.ico',
    content: [
      `Special untuk mendukung penuh tugas dan pekerjaan perkantoran office dalam operasionalnya, dengan inventori stok khusus untuk penggunaan aplikasi stok inventori management, aplikasi invoice untuk mencetak faktur penjualan maupun faktur pajak penjualan , dilengkapi dengan pencatatan tender yang dikerjakan lengkap dengan status, agenda project hingga accounting akuntansi dalam bekerja menggunakan aplikasi kami ini`,
      `Develope aplikasi khusus untuk masing masing divisi dengan aplikasi invoice guna mencetak faktur mencatat tender, aplikasi stok untuk cek persediaan, dan akuntansi untuk kebutuhan accounting hingga stor pajak semua semakin mudah dengan aplikasi office develope kami ini.`
    ]
  },
  {
    name: 'aplikasisekolah',
    title: 'Aplikasi Sekolah',
    desc:
      'Elearn online ERP absensi pembayaran sekolah aplikasi sekolah online',
    img:
      'https://1.bp.blogspot.com/-o37247tPyxw/YMDUOTJp9TI/AAAAAAAAPGI/lDCq-rmxEuo1BCJzJMX2JpBzcMnFvuPJQCLcBGAsYHQ/s256/USB%2BRemovable.ico',
    content: [
      `Special untuk bidang edukasi dengan hadirnya aplikasi sekolah develope kami agar mempermudah pihak sekolah dalam hal pembukuan baik untuk e-learn online dengan berbagai variant mulai dari simple untuk ujian, upload media untuk dikerjakan siswa dan penilaian langsung untuk ujian, absensi sekolah , pemabayaran dan keuangan sekolah, hingga ERP all in one terintegrasi dalam digunakan .`,
      `Penggunaan nya yang sangat mudah berbasis clouds memudahkan dalam berbagai hal baik untuk siswa murid login dan cek berbagai ujian jadwal pelajaran hingga kebutuhan untuk para guru menyiapkan materi ujian dan admin TU untuk cek pembayaran dan laporan kondisi keuangan sekolah, CMS sekolah juga akan mempermudah membuat website sekolah secara cepat karena secara skema dan konsep sudah kami siapkan agar mempermudah web admin sekolah bekerja dalam hal update berita sekolah serta lain nya.`
    ]
  },
  {
    name: 'android',
    title: 'Aplikasi android',
    desc: 'Pembuatan aplikasi android APK',
    img:
      'https://1.bp.blogspot.com/-BuMSRTKQ0IE/YMDUUALqNcI/AAAAAAAAPHU/IHkaS4EnqGUvxh35aT2a-ZBMEehQDjR0wCLcBGAsYHQ/s256/webcam.ico',
    content: [
      `Sentuh dan semakin dekat dengan pelanggan menggunakan aplikasi android, kami menyediakan jasa pembuatan aplikasi android untuk anda, develope menggunakan real mobile apps flutter dan dart untuk proses deploy aplikasi android anda sehingga aman dan memdudahkan dalam proses upload ke play store google atau untuk dibagikan secara langsung kepada pelanggan anda , dengan aplikasi android milik sendiri akan mempermudah dalam memperluas dan mengenalkan bisnis secara luas`,
      `Secara skema kami juga biasanya mengintergasikan aplikasi android dengan website maupun aplikasi untuk kemudahan dalam bekerja dan menerima update secara cepat via android apps.`
    ]
  },
  {
    name: 'webapp',
    title: 'Website Application',
    desc: 'Website terintegrasi dengan aplikasi via web app',
    img:
      'https://1.bp.blogspot.com/-q9LiKX_OGyM/YMDUTayTArI/AAAAAAAAPHI/Tfp3qPs5b1c10bMbuSAw5dYd2ZyQ41_SwCLcBGAsYHQ/s256/search.ico',
    content: [
      `Pastikan menggunakan layanan web app premium agar mendapatkan fasilitas website yang secara otomatis terintegrasi dengan aplikasi, dengan kemampuan ini tentunya akan memberikan kemudahan dalam berkerja secara efektif real time online`,
      `Dalam satu apps hadir dengan aplikasi untuk pembukuan dan transaksi hingga kebutuhan website agar pelanggan mendapatkan update secara berkala sekaligus menjadi icon usaha di dunia online net maupun media marketing online .`
    ]
  },
  {
    name: 'allinone',
    title: 'All in one dengan aplikasi kami',
    desc:
      'Sempurnakan dengan integrasi technology website , aplikasi hingga android apk',
    img:
      'https://1.bp.blogspot.com/-BBdCJB4Y_ZE/YMDUStkgSLI/AAAAAAAAPHE/9nhpfW_Z-PojaJmw9c8KqqcYxeD47egJQCLcBGAsYHQ/s256/search%2Bv2.ico',
    content: [
      `Mudahkan segala nya dengan website aplikasi kami untuk bekerja dengan sentuhan ini kini dunia benar benar dalam genggaman anda, tidak sekedar aplikasi untuk bekerja dan menunjang pembukuan tetapi juga website untuk mdei informasi plus aplikasi android dalam satu sentuha all in one aplikasi benar benar terintegrasi secara cerdas untuk mendukung usaha anda.`
    ]
  },
  {
    name: 'tokoshop',
    title: 'Solusi retail',
    desc: 'Percayakan kepada kami untuk project technology shop retail anda',
    img:
      'https://1.bp.blogspot.com/-PVbmQQvCHGg/YMDURaLwwKI/AAAAAAAAPGw/T4xCSRZOK5YctOZXY58XK-BFnZ7GtUlBQCLcBGAsYHQ/s256/network%2Bconnections.ico',
    content: [
      `Kebutuhan dalam bekerja dengan untuk technology yang terbaik dengan menerima request anda selanjutnya team kami akan bekerja memikirkan segala sesuatu yang terbaik dalam all in one konsep untuk yang terbaik dalam skema dan pengaturan konsep retail toko shop bekerja, baik secara aplikasi mesin kasir bekerja dengan divisi stok inventori, admin database, kasir penjualan hingga kebutuhan owner dengan fasilitas cek monitoring data real time, dukungan website agar toko menjadi lebih maju dengan technology up to date untuk semakin memudahkan pelanggan dalam proses order . Dan tentunya dalam proses pengembangan untuk full system anda kami akan bekerja dengan berbagai technology demi tujuan menyentuh kesempurnaan dalam digunakan baik aplikasi pembukuan , website modern hingga apk android.`
    ]
  },
  {
    name: 'restoran',
    title: 'Solusi restoran',
    desc: 'Lengkapi technolgy resto clouds anda bersama kami',
    img:
      'https://1.bp.blogspot.com/-PidIVtiOsCw/YMDURRYvO4I/AAAAAAAAPG0/bxdR5YM-M94PGqzRuTvJGgnlUHNXjnApgCLcBGAsYHQ/s256/network%2Bplaces.ico',
    content: [
      `untuk hasil yang terbaik technology restoran cafe rumah makan maka, percayakan segala nya kepada team dev kami untuk bekerja memberikan kesempurnaan berbagai system digunakan baik aplikasi restoran cafe rumah makan dengan pembagian divisi kasir, waiters, kitchen hingga admin untuk bekerja dan tentunya owner agar lebih mudah monitoring pembukuan usaha secara detail. bukan hanya sekedar aplikasi tetapi ada sebuah website yang infomratif untuk memberikan informasi yang memudahkan pelanggan dalam berbagai kebutuhan, serta kemampuan nya dalam  handling online order menjadi lebih baik untuk usaha bisnis kuliner restoran cafe rumah makan. dan untuk kesempurnaan aplikasi android APK siap untuk launching kan brand resto kuliner mu`
    ]
  },
  {
    name: 'company',
    title: 'Solusi company office',
    desc: 'Company profile website dan aplikasi office',
    img:
      'https://1.bp.blogspot.com/-Bib-0XTYtag/YMDUTgh_t7I/AAAAAAAAPHQ/aRCjlZ96tBEvPdXOE038tADQLPz2bc-igCLcBGAsYHQ/s256/subscriptions.ico',
    content: [
      `Memudahkan dan mobilitas tinggi untuk kebutuhan office perusahaan pabrikan dalam digunakan, percayakan kami untuk handling segala kebutuhan secara full clouds based bekerja dengan cloud computing untuk mobilitas efisiensi dan mempercepat berbagai pekerjaan, dengan dukungan aplikasi bekerja secara cepat, website company profile yang elegan akan memberikan informasi serta benefit dengan technology yang digunakan dalam build website hingga kebutuhan untuk APK android menyempurnakan perusahaan.`
    ]
  },
  {
    name: 'school',
    title: 'Solusi sekolah',
    desc: 'Sekolah modern trend milenial era.',
    img:
      'https://1.bp.blogspot.com/-uk7za4mdhzs/YMDUQIKIUsI/AAAAAAAAPGk/dyBZw0W78gULC2F89sdsab87cZm2kB29QCLcBGAsYHQ/s256/mouse.ico',
    content: [
      `Pastikan universitas dan sekolah anda mempercayakan technology terbaik kepada kami untuk menjadikan sekolah semakin maju dan berkembang , PG, SD , SMP, SMA , Universitas akan sempurna dengan menggunakan dukungan technology terbaik dalam bekerja bukan hanya sebuah aplikasi yang mendukung pembukuan sekolah , tetapi hadir website untuk memberikan informasi yang lengkap kepada calon siswa hingga orang tua wali murid untuk memperoleh informasi secara cepat.`
    ]
  },
  {
    name: 'photography',
    title: 'Jasa Photography',
    desc: 'Layanan photography services.',
    img:
      'https://1.bp.blogspot.com/-RlpqBrn5-AM/YMDUQQi09tI/AAAAAAAAPGo/4KldqdZv-F4wDPiEMmaxbs219oGbtJWPgCLcBGAsYHQ/s256/my%2Bpictures.ico',
    content: [
      `Tema kami pun memiliki skill terbaik di bidang photography , baik untuk penggunaan company profile, portfolio, untuk sekolah dan lain lain, dengan photography akan memberikan sensasi dan tingkat benerfit tinggi dalam digunakan untuk desain website , majalah, e-catalog dan lain sebagai nya, dengan team yang berpengalaman dan memiliki skill serta prestasi juara dalam lomba foto maka tidak perlu meragukan kami untuk memberikan hasil yang terbaik .`
    ]
  },
  {
    name: 'video',
    title: 'Jasa Video Cinema',
    desc: 'Video dan liputan hingga cinema services.',
    img:
      'https://1.bp.blogspot.com/-HLJxJG_Yeq4/YMDUQw6CFuI/AAAAAAAAPGs/AqaM6OyC_X8ABGPWVjY-tfpx_2Rb8UaqACLcBGAsYHQ/s256/my%2Bvideos.ico',
    content: [
      `Era saat ini video adalah syarat mutlak agar media anda semakin lengkap baik untuk penyampaian produk, tutorial, edukasi, hingga presentasi , kunci sukses berada pada video, bukan sekedar video liputan dan presentasi saja tapi hadirkan cinema video untuk memberikan kepercayaan terbaik.`
    ]
  },
  {
    name: 'digitalart',
    title: 'Digital Art',
    desc: 'Editing foto digital imaging.',
    img:
      'https://1.bp.blogspot.com/-Rk6tHH3HJfI/YMDUO2OQqWI/AAAAAAAAPGQ/n9PscvpygvQuhhGG7uqnQw_jExwdx9v0ACLcBGAsYHQ/s256/desktop.ico',
    content: [
      `Hei kini lebih mudah dalam membuat brosur , hingga proses edit foto dengan sempurna via digital art imaging, dimana slicecut dan penggabungan berbagai photo untuk menghasilkan karya baru terbaik digunakan pada brosur hingga e-magazine dan catalog baik untuk company profile, produk katalog,  menu makanan minuman restoran, majalah sekolah dan lain sebagai nya.`
    ]
  }
];

export default articles;
