import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
const ArticleList = ({ articles }) => (
  <>
    <Helmet>
<meta name="google-site-verification" content="WH5yK5pEky5qND2FOW3_hvm3VlPkjRtV731y81BcsTc" />
      <title>Pembuatan website aplikasi surabaya</title>
      <meta
        name="description"
        content="Aplikasi toko, kasir penjualan, akuntansi invoice, sekolah , restoran cafe rumah makan dan pembuatan website terbaru"
      />
      <meta content="Pembuatan website aplikasi surabaya" property="og:title" />
      <meta
        content="Aplikasi toko, kasir penjualan, akuntansi invoice, sekolah , restoran cafe rumah makan dan pembuatan website terbaru"
        property="og:description"
      />
      <meta
        content="https://1.bp.blogspot.com/-t2VnKnlI0l8/Xzt04NUjFAI/AAAAAAAALN8/TUprEcTdVlwmkL3a1sXnbtDcGHUv-3kxQCLcBGAsYHQ/s1200/aplikasi%2Btoko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25283%2529.jpg"
        property="og:image"
      />
      <meta content="https://axcora.com/getaxcoracms" property="og:author" />
      <meta
        content="Pembuatan website aplikasi surabaya"
        name="facebook:title"
      />
      <meta content="website" property="og:type" />
      <meta
        content="https://www.facebook.com/mesinkasircomplete"
        property="facebook:author"
      />
      <meta
        content="https://1.bp.blogspot.com/-t2VnKnlI0l8/Xzt04NUjFAI/AAAAAAAALN8/TUprEcTdVlwmkL3a1sXnbtDcGHUv-3kxQCLcBGAsYHQ/s1200/aplikasi%2Btoko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25283%2529.jpg"
        name="facebook:image"
      />
      <meta
        content="Pembuatan website aplikasi surabaya"
        property="og:site_name"
      />
      <meta
        content="Aplikasi toko, kasir penjualan, akuntansi invoice, sekolah , restoran cafe rumah makan dan pembuatan website terbaru"
        property="facebook:description"
      />
      <meta content="summary_large_image" name="twitter:card" />
      <meta
        content="https://twitter.com/hockeycomp"
        property="twitter:author"
      />
      <meta content="@hockeycomp" name="twitter:site" />
      <meta
        content="https://1.bp.blogspot.com/-t2VnKnlI0l8/Xzt04NUjFAI/AAAAAAAALN8/TUprEcTdVlwmkL3a1sXnbtDcGHUv-3kxQCLcBGAsYHQ/s1200/aplikasi%2Btoko%2Bmesin%2Bkasir%2Bonline%2Blaravel%2Bpoint%2Bof%2Bsale%2Bcashier%2Bapps%2B%25283%2529.jpg"
        name="twitter:image"
      />
      <meta
        content="Aplikasi toko, kasir penjualan, akuntansi invoice, sekolah , restoran cafe rumah makan dan pembuatan website terbaru"
        name="twitter:description"
      />
      <meta
        content="Pembuatan website aplikasi surabaya"
        name="twitter:title"
      />
      <meta content="index, follow" name="robots" />
    </Helmet>
    <div className="lead col-12 col-md-12 p-3 p-md-5 text-center">
      <p>
        Pilih layanan kami dibawah ini.slide kekiri-kanan dan klik pada menu
        untuk info details
      </p>
    </div>
    <div
      className="uk-position-relative uk-visible-toggle uk-light"
      tabindex="-1"
      uk-slider="center: true "
    >
      <ul className="uk-slider-items uk-grid">
        {articles.map((article, key) => (
          <Link
            className="uk-animation-slide-top"
            key={key}
            to={`/article/${article.name}`}
          >
            <li className="uk-width-4-5">
              <div className="uk-panel">
                <img
                  alt="pembuatan website aplikasi surabaya"
                  className="img-fluid rounded"
                  src={article.img}
                />
                <h3 className="text-light">
                  <strong>{article.title}</strong>
                </h3>
              </div>{' '}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  </>
);

export default ArticleList;
